export default {
  filteringPanel: {
    loaded: false,
    selected: {},
    districts: [],
    filters: [
      {
        type: 'select',
        dataType: 'territory',
        title: 'Territory',
        tooltip: 'Distributor billing territory',
        name: 'territory',
        trackby: 'id',
        label: 'label',
        multiple: true,
        options: [],
        selected: {},
        visible: true
      },
      {
        type: 'select',
        dataType: 'string',
        title: 'Account',
        tooltip: 'Account name',
        name: 'account',
        trackby: 'id',
        label: 'label',
        options: [],
        selected: {},
        multiple: false,
        async: true,
        loading: false,
        startsWith: false
      },
      {
        type: 'daterange',
        dataType: 'datetime',
        defaultRange: 'Last quarter',
        title: 'Previous Period',
        tooltip: 'Previous Period',
        name: 'period',
        options: []
      },
      {
        type: 'daterange',
        dataType: 'datetime',
        defaultRange: 'This quarter',
        title: 'Current Period',
        tooltip: 'Current Period',
        name: 'period2',
        options: []
      }
    ]
  }
}
