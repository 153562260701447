<template>
  <div class="animated fadeIn">
    <b-card>
      <h2>Account Overview - Distribution</h2>
      <br />
      <filtering-panel
        ref="filteringPanel"
        mode="server"
        dataset-name="distribution-accounts-overview"
        :show-labels="true"
        :load-dictionaries="loadDictionaries"
        :districts="filteringPanel.districts"
        :filters="filteringPanel.filters"
        :loaded="filteringPanel.loaded"
        @search="getData"
        :compact="false"
        @change="onFilteringPanelChange"
        @async-search="onAsyncSearch"
        @dict-loaded="onFilteringPanelLoad"
        @custom-filter-selected="onCustomFilterSelected"
        @state-changed="onFilteringPanelStateChange"
      />

      <hr />
      <br />
      <br />
      <br />
      <br />
      <hr />

      <template v-if="dataSection.visible">
        <b-row>
          <b-col sm="12" lg="6" align="left">
            <b-row>
              <b-col>
                <h3>
                  <b-row class="p-2">
                    <b-col lg="5">
                      Account name:
                    </b-col>
                    <b-col>
                      <small>{{ accountDetails.accountName }}</small>
                    </b-col>
                  </b-row>
                  <b-row class="p-2">
                    <b-col lg="5">
                      Address:
                    </b-col>
                    <b-col>
                      <small>{{ accountDetails.street }}</small>
                    </b-col>
                  </b-row>
                  <b-row class="p-2">
                    <b-col lg="5" />
                    <b-col>
                      <small
                        >{{ accountDetails.city }} {{ accountDetails.state }}
                        {{ accountDetails.code }}</small
                      >
                    </b-col>
                  </b-row>
                  <b-row class="p-2">
                    <b-col lg="5" />
                    <b-col>
                      <small>{{ accountDetails.phone }}</small>
                    </b-col>
                  </b-row>
                  <b-row class="p-2" v-if="accountDetails.chain !== 'NONE'">
                    <b-col lg="5">
                      Chain:
                    </b-col>
                    <b-col>
                      <small>{{ accountDetails.chain }}</small>
                    </b-col>
                  </b-row>
                  <b-row class="p-2">
                    <b-col lg="5">
                      Premise:
                    </b-col>
                    <b-col>
                      <small>{{ accountDetails.premise }}</small>
                    </b-col>
                  </b-row>

                  <b-row class="p-2">
                    <b-col lg="5" v-if="accountDetails.contacts.length > 0">
                      Contacts:
                    </b-col>
                    <b-col>
                      <template
                        v-for="(contact, index) in accountDetails.contacts"
                      >
                        <b-badge
                          variant="dark"
                          class="m-1"
                          :id="`bbt-${contact.contactid}`"
                          :key="`bb-${uid}-${index}`"
                        >
                          {{ contact.contactname }}
                        </b-badge>
                        <b-popover
                          :target="`bbt-${contact.contactid}`"
                          :key="`po-${uid}-${index}`"
                          triggers="hover"
                          placement="top"
                        >
                          <ul>
                            <li>Name: {{ contact.contactname }}</li>
                            <li>Email: {{ contact.email }}</li>
                            <li>Phone: {{ contact.phone }}</li>
                            <li>Title: {{ contact.title }}</li>
                          </ul>
                        </b-popover>
                      </template>
                    </b-col>
                  </b-row>
                  <hr />
                  <br />
                  <br />
                  <br />
                  <br />
                  <hr />
                  <div>
                    <b-row class="p-2">
                      <b-col lg="5">
                        First order:
                      </b-col>
                      <b-col>
                        <small>{{ accountDetails.firstOrder }}</small>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="5">
                        Average invoice:
                      </b-col>
                      <b-col>
                        <small>{{ accountDetails.avgInvoice }}</small>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="5">
                        Invoice count:
                      </b-col>
                      <b-col>
                        <small>{{ accountDetails.invCount }}</small>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="5">
                        Estimated days between invoices:
                      </b-col>
                      <b-col>
                        <small>{{ accountDetails.avgPeriod }}</small>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="5">
                        Last invoice:
                      </b-col>
                      <b-col>
                        <small>{{ accountDetails.lastInvoice }}</small>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="5">
                        Estimated next invoice:
                      </b-col>
                      <b-col>
                        <small>{{ accountDetails.nextInvoice }}</small>
                      </b-col>
                    </b-row>
                    <b-row class="p-2">
                      <b-col lg="5">
                        Invoice regularity status:
                      </b-col>
                      <b-col>
                        <small>{{ accountDetails.regularity }}</small>
                      </b-col>
                    </b-row>
                  </div>
                </h3>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12" lg="6" align="center">
            <b-row>
              <b-col sm="12" lg="12" align="center">
                <callout-box
                  ref="callout1"
                  :loading="callouts.callout1.loading"
                  :postfix="callouts.callout1.postfix"
                  :prefix="callouts.callout1.prefix"
                  :hide-sign="callouts.callout1.hideSign"
                  :bg-color-class="callouts.callout1.bgColorClass"
                  :width="'95%'"
                  :value="callouts.callout1.value"
                  :header="callouts.callout1.header"
                  :subheader="callouts.callout1.subheader"
                  :footer="callouts.callout1.footer"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6" lg="6" align="center">
                <callout-box
                  ref="callout2"
                  :loading="callouts.callout2.loading"
                  :postfix="callouts.callout2.postfix"
                  :prefix="callouts.callout2.prefix"
                  :hide-sign="callouts.callout2.hideSign"
                  :bg-color-class="callouts.callout2.bgColorClass"
                  :width="'90%'"
                  :value="callouts.callout2.value"
                  :header="callouts.callout2.header"
                  :subheader="callouts.callout2.subheader"
                  :footer="callouts.callout2.footer"
                />
              </b-col>
              <b-col sm="6" lg="6" align="center">
                <callout-box
                  ref="callout3"
                  :loading="callouts.callout3.loading"
                  :postfix="callouts.callout3.postfix"
                  :prefix="callouts.callout3.prefix"
                  :hide-sign="callouts.callout3.hideSign"
                  :bg-color-class="callouts.callout3.bgColorClass"
                  :width="'90%'"
                  :value="callouts.callout3.value"
                  :header="callouts.callout3.header"
                  :subheader="callouts.callout3.subheader"
                  :footer="callouts.callout3.footer"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <hr />
        <br />
        <br />
        <br />
        <br />
        <hr />
        <b-row>
          <b-col>
            <g-chart-custom
              ref="chart1L"
              :default-mode="charts.chart1L.isDefaultMode"
              title="Historical sales and activity"
              :subtitle="charts.chart1L.subtitle"
              type="ComboChart"
              :loading="charts.chart1L.loading"
              :data="charts.chart1L.chartData"
              :options="charts.chart1L.chartOptions"
              @chart-element-selected="drilldownChart1L"
            >
              <template #legend>
                <b-popover
                  target="chart1L-ddi"
                  triggers="hover"
                  placement="right"
                >
                  <span>Chart drilldown available</span>
                </b-popover>
                <i id="chart1L-ddi" class="ml-2 fa fa-share fa-rotate-90" />
              </template>

              <template #chartTopBar>
                <b-row align-h="between">
                  <b-col />
                  <b-col class="text-right">
                    <b-button
                      v-show="charts.chart1L.isDefaultMode"
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart1L.isDefaultMode = !charts.chart1L
                          .isDefaultMode
                      "
                    >
                      <font-awesome-icon icon="list" />
                    </b-button>
                    <b-button
                      v-show="!charts.chart1L.isDefaultMode"
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart1L.isDefaultMode = !charts.chart1L
                          .isDefaultMode
                      "
                    >
                      <font-awesome-icon icon="chart-pie" />
                    </b-button>
                  </b-col>
                </b-row>
              </template>
              <template #table>
                <table-custom
                  :id="`chart1LTable-${uid}`"
                  :name="tables.chart1LTable.name"
                  :loading="tables.chart1LTable.isLoading"
                  :data="tables.chart1LTable.dataSet"
                  :options="tables.chart1LTable.options"
                  @mounted="onChart1LTableMount"
                />
              </template>
            </g-chart-custom>
          </b-col>
          <b-col>
            <g-chart-custom
              ref="chart1R"
              :default-mode="charts.chart1R.isDefaultMode"
              title="Historical sales and activity"
              :subtitle="charts.chart1R.subtitle"
              type="ComboChart"
              :loading="charts.chart1R.loading"
              :data="charts.chart1R.chartData"
              :options="charts.chart1R.chartOptions"
              @chart-element-selected="drilldownChart1R"
            >
              <template #legend>
                <b-popover
                  target="chart1R-ddi"
                  triggers="hover"
                  placement="right"
                >
                  <span>Chart drilldown available</span>
                </b-popover>
                <i id="chart1R-ddi" class="ml-2 fa fa-share fa-rotate-90" />
              </template>

              <template #chartTopBar>
                <b-row align-h="between">
                  <b-col />
                  <b-col class="text-right">
                    <b-button
                      v-show="charts.chart1R.isDefaultMode"
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart1R.isDefaultMode = !charts.chart1R
                          .isDefaultMode
                      "
                    >
                      <font-awesome-icon icon="list" />
                    </b-button>
                    <b-button
                      v-show="!charts.chart1R.isDefaultMode"
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart1R.isDefaultMode = !charts.chart1R
                          .isDefaultMode
                      "
                    >
                      <font-awesome-icon icon="chart-pie" />
                    </b-button>
                  </b-col>
                </b-row>
              </template>
              <template #table>
                <table-custom
                  :id="`chart1RTable-${uid}`"
                  :name="tables.chart1RTable.name"
                  :loading="tables.chart1RTable.isLoading"
                  :data="tables.chart1RTable.dataSet"
                  :options="tables.chart1RTable.options"
                  @mounted="onChart1RTableMount"
                />
              </template>
            </g-chart-custom>
          </b-col>
        </b-row>

        <hr />
        <br />
        <br />
        <br />
        <br />
        <hr />

        <b-row>
          <b-col>
            <g-chart-custom
              ref="chart2L"
              :default-mode="charts.chart2L.isDefaultMode"
              title="Sales by product category"
              :subtitle="charts.chart2L.subtitle"
              type="BarChart"
              :loading="charts.chart2L.loading"
              :data="charts.chart2L.chartData"
              :options="charts.chart2L.chartOptions"
              @chart-element-selected="drilldownChart2L"
            >
              <template #legend>
                <b-popover
                  target="chart2L-ddi"
                  triggers="hover"
                  placement="right"
                >
                  <span>Chart drilldown available</span>
                </b-popover>
                <i id="chart2L-ddi" class="ml-2 fa fa-share fa-rotate-90" />
              </template>

              <template #table>
                <table-custom
                  :name="tables.chart2LTable.name"
                  :loading="tables.chart2LTable.isLoading"
                  :data="tables.chart2LTable.dataSet"
                  :options="tables.chart2LTable.options"
                  @mounted="onChart2LTableMount"
                />
              </template>
              <template #chartTopBar>
                <b-row align-h="between">
                  <b-col />
                  <b-col class="text-right">
                    <b-button
                      v-show="charts.chart2L.isDefaultMode"
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart2L.isDefaultMode = !charts.chart2L
                          .isDefaultMode
                      "
                    >
                      <font-awesome-icon icon="list" />
                    </b-button>
                    <b-button
                      v-show="!charts.chart2L.isDefaultMode"
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart2L.isDefaultMode = !charts.chart2L
                          .isDefaultMode
                      "
                    >
                      <font-awesome-icon icon="chart-pie" />
                    </b-button>
                  </b-col>
                </b-row>
              </template>
              <template #table>
                <table-custom
                  :name="tables.chart2LTable.name"
                  :loading="tables.chart2LTable.isLoading"
                  :data="tables.chart2LTable.dataSet"
                  :options="tables.chart2LTable.options"
                  @mounted="onChart2LTableMount"
                />
              </template>
            </g-chart-custom>
          </b-col>
          <b-col>
            <g-chart-custom
              ref="chart2R"
              :default-mode="charts.chart2R.isDefaultMode"
              title="Sales by product category"
              :subtitle="charts.chart2R.subtitle"
              type="BarChart"
              :loading="charts.chart2R.loading"
              :data="charts.chart2R.chartData"
              :options="charts.chart2R.chartOptions"
              @chart-element-selected="drilldownChart2R"
            >
              <template #legend>
                <b-popover
                  target="chart2R-ddi"
                  triggers="hover"
                  placement="right"
                >
                  <span>Chart drilldown available</span>
                </b-popover>
                <i id="chart2R-ddi" class="ml-2 fa fa-share fa-rotate-90" />
              </template>

              <template #table>
                <table-custom
                  :name="tables.chart2RTable.name"
                  :loading="tables.chart2RTable.isLoading"
                  :data="tables.chart2RTable.dataSet"
                  :options="tables.chart2RTable.options"
                  @mounted="onChart2RTableMount"
                />
              </template>
              <template #chartTopBar>
                <b-row align-h="between">
                  <b-col />
                  <b-col class="text-right">
                    <b-button
                      v-show="charts.chart2R.isDefaultMode"
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart2R.isDefaultMode = !charts.chart2R
                          .isDefaultMode
                      "
                    >
                      <font-awesome-icon icon="list" />
                    </b-button>
                    <b-button
                      v-show="!charts.chart2R.isDefaultMode"
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart2R.isDefaultMode = !charts.chart2R
                          .isDefaultMode
                      "
                    >
                      <font-awesome-icon icon="chart-pie" />
                    </b-button>
                  </b-col>
                </b-row>
              </template>
              <template #table>
                <table-custom
                  :name="tables.chart2RTable.name"
                  :loading="tables.chart2RTable.isLoading"
                  :data="tables.chart2RTable.dataSet"
                  :options="tables.chart2RTable.options"
                  @mounted="onChart2RTableMount"
                />
              </template>
            </g-chart-custom>
          </b-col>
        </b-row>

        <hr />
        <br />
        <br />
        <br />
        <br />
        <hr />
        <b-row>
          <b-col>
            <g-chart-custom
              ref="chart3L"
              :default-mode="charts.chart3L.isDefaultMode"
              title="Sales by product"
              :subtitle="charts.chart3L.subtitle"
              type="BarChart"
              :loading="charts.chart3L.loading"
              :data="charts.chart3L.chartData"
              :options="charts.chart3L.chartOptions"
              @chart-element-selected="drilldownChart3L"
            >
              <template #legend>
                <b-popover
                  target="chart3L-ddi"
                  triggers="hover"
                  placement="right"
                >
                  <span>Chart drilldown available</span>
                </b-popover>
                <i id="chart3L-ddi" class="ml-2 fa fa-share fa-rotate-90" />
              </template>
              <template #filter>
                <filter-panel
                  ref="filterPanelSalesByProduct"
                  @search-triggered="getAccountSalesByProductChartData"
                  :show-countries="false"
                  :show-states="false"
                  :show-product-categories="true"
                  :show-date-range="false"
                />
              </template>
              <template #chartTopBar>
                <b-row align-h="between">
                  <b-col />
                  <b-col class="text-right">
                    <b-button
                      v-show="charts.chart3L.isDefaultMode"
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart3L.isDefaultMode = !charts.chart3L
                          .isDefaultMode
                      "
                    >
                      <font-awesome-icon icon="list" />
                    </b-button>
                    <b-button
                      v-show="!charts.chart3L.isDefaultMode"
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart3L.isDefaultMode = !charts.chart3L
                          .isDefaultMode
                      "
                    >
                      <font-awesome-icon icon="chart-pie" />
                    </b-button>
                  </b-col>
                </b-row>
              </template>
              <template #table>
                <table-custom
                  :name="tables.chart3LTable.name"
                  :loading="tables.chart3LTable.isLoading"
                  :data="tables.chart3LTable.dataSet"
                  :options="tables.chart3LTable.options"
                  @mounted="onChart3LTableMount"
                />
              </template>
            </g-chart-custom>
          </b-col>
          <b-col>
            <g-chart-custom
              ref="chart3R"
              :default-mode="charts.chart3R.isDefaultMode"
              title="Sales by product"
              :subtitle="charts.chart3R.subtitle"
              type="BarChart"
              :loading="charts.chart3R.loading"
              :data="charts.chart3R.chartData"
              :options="charts.chart3R.chartOptions"
              @chart-element-selected="drilldownChart3R"
            >
              <template #legend>
                <b-popover
                  target="chart3R-ddi"
                  triggers="hover"
                  placement="right"
                >
                  <span>Chart drilldown available</span>
                </b-popover>
                <i id="chart3R-ddi" class="ml-2 fa fa-share fa-rotate-90" />
              </template>
              <template #filter>
                <filter-panel
                  ref="filterPanelSalesByProduct2"
                  @search-triggered="getAccountSalesByProductChartData"
                  :show-countries="false"
                  :show-states="false"
                  :show-product-categories="true"
                  :show-date-range="false"
                />
              </template>
              <template #chartTopBar>
                <b-row align-h="between">
                  <b-col />
                  <b-col class="text-right">
                    <b-button
                      v-show="charts.chart3R.isDefaultMode"
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart3R.isDefaultMode = !charts.chart3R
                          .isDefaultMode
                      "
                    >
                      <font-awesome-icon icon="list" />
                    </b-button>
                    <b-button
                      v-show="!charts.chart3R.isDefaultMode"
                      class="mb-2"
                      variant="outline-primary"
                      @click="
                        charts.chart3R.isDefaultMode = !charts.chart3R
                          .isDefaultMode
                      "
                    >
                      <font-awesome-icon icon="chart-pie" />
                    </b-button>
                  </b-col>
                </b-row>
              </template>
              <template #table>
                <table-custom
                  :name="tables.chart3RTable.name"
                  :loading="tables.chart3RTable.isLoading"
                  :data="tables.chart3RTable.dataSet"
                  :options="tables.chart3RTable.options"
                  @mounted="onChart3RTableMount"
                />
              </template>
            </g-chart-custom>
          </b-col>
        </b-row>
      </template>
      <hr />
    </b-card>
  </div>
</template>

<script>
import moment from 'moment'

import GChartCustom from '@/components/GChartCustom'
import CalloutBox from '@/components/CalloutBox'

import FilteringPanel from '@/components/FilteringPanel'
import FilterPanel from '@/components/FilterPanelSimple'
import fp from './filters'
import tables from './tables'
import charts from './charts'
import callouts from './callouts'
import chart1LMethods from './chart1L.methods'
import chart1RMethods from './chart1R.methods'
import chart2LMethods from './chart2L.methods'
import chart2RMethods from './chart2R.methods'
import chart3LMethods from './chart3L.methods'
import chart3RMethods from './chart3R.methods'

export default {
  name: 'Overview',
  components: {
    FilteringPanel,
    FilterPanel,
    GChartCustom,
    CalloutBox
  },
  data: function () {
    return {
      isLoading: false,
      filteringPanel: fp.filteringPanel,
      tables: tables,
      dataSection: {
        visible: true
      },
      accountDetails: {
        accountName: '',
        firstOrder: '',
        avgInvoice: '',
        lastInvoice: '',
        nextInvoice: '',
        avgPeriod: '',
        regularity: '',
        invCount: '',

        chain: '',
        street: '',
        city: '',
        state: '',
        code: '',
        phone: '',
        premise: '',
        contacts: []
      },
      callouts: callouts,
      charts: charts
    }
  },
  mounted () {
    //this.getData();
  },
  methods: {
    ...chart1LMethods,
    ...chart1RMethods,
    ...chart2LMethods,
    ...chart2RMethods,
    ...chart3LMethods,
    ...chart3RMethods,
    onAsyncSearch (payload) {
      console.log('onAsyncSearch.payload', payload)
      if (payload.filterName === 'account') {
        let accountsFilter = this.filteringPanel.filters.find(
          f => f.name === 'account'
        )

        accountsFilter.loading = true

        this.$api
          .post('dictionaries/accounts', {
            query: payload.query,
            starts_with: payload.startsWith
          })
          .then(response => {
            accountsFilter.loading = false

            accountsFilter.options = response.map(u => ({
              id: u.id,
              label: u.name
            }))
          })
      }
    },
    async loadDictionaries () {
      const territories = async () => {
        let response = await this.$api.get('dictionaries/territories')

        this.filteringPanel.filters.find(
          f => f.name === 'territory'
        ).options = response.map(u => ({
          id: u.ID,
          label: u.Name
        }))
      }

      const districts = async () => {
        let response = await this.$api.post('dictionaries/countries/mapped', {
          ignore_restrictions: false
        })

        this.filteringPanel.districts = response

        /*
        this.filteringPanel.filters.find(
          f => f.name === "country"
        ).options = this.$helpers.getDistinctArray(
          response,
          "country",
          "country",
          "id",
          "label"
        );
        */
      }

      Promise.all([territories(), districts()]).then(
        () => (this.filteringPanel.loaded = true)
      )
    },
    onFilteringPanelStateChange () {
      this.dataSection.visible = !this.$refs.filteringPanel.expressionBuilder
        .visible

      console.log('this.dataSection.visible:', this.dataSection.visible)
    },
    onFilteringPanelLoad () {
      //if (this.$refs.filteringPanel.panelState.isCustomFiltersBar) this.getData();
      this.getData()
    },
    onFilteringPanelChange (payload, controlName) {
      //  this.updateDateRanges(payload, controlName)
      this.filterData(payload)
    },
    updateDateRanges (payload, controlName) {
      /*
      let filter = this.$refs.filteringPanel.selected

      
      const periodLength = moment(filter.period.endDate).diff(
        filter.period.startDate,
        'days'
      )
     
      const comparisonPeriod = {
        startDate: moment(filter.period.startDate)
          .subtract(periodLength + 1, 'days')
          .format('YYYY-MM-DD'),
        endDate: moment(filter.period.startDate)
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
      }

      //if changed main period then update comparison period. Otherwise keep it as is
     
      if (controlName == 'period') {
        this.$refs.filteringPanel.$refs['period2'][0].setValue(
          comparisonPeriod,
          {
            triggerChanged: false
          }
        )
      }
      */
    },
    onCustomFilterSelected () {
      this.getData()
    },
    filterData (e) {
      this.filteringPanel.selected = e
      //let self = this;
      //this.filteringPanel.selected = e;
    },
    getData: function () {
      //      if (!this.$refs.filteringPanel.selected.accountname) return;
      console.log(
        'accountOverview.getData.filteringPanel.selected',
        this.$refs.filteringPanel.selected
      )

      let filter1 = this.$refs.filteringPanel.selected
      let filter2 =
        !this.$refs.filterPanelSalesByProduct ||
        this.$refs.filterPanelSalesByProduct.selected

      let combinedFilter = {
        ...filter1
      }
      combinedFilter.accountname = combinedFilter.account
        ? combinedFilter.account.label
        : ''

      combinedFilter.productcategory = filter2.productcategory
        ? filter2.productcategory
        : ''

      console.log(
        'accountOverview.getData.combinedFilter',
        JSON.stringify(combinedFilter)
      )

      this.drawAccountDetails(combinedFilter)
      this.drawCallouts(combinedFilter)
      this.drawChart1L(combinedFilter)
      this.drawChart1R(combinedFilter)
      this.drawChart2L(combinedFilter)
      this.drawChart2R(combinedFilter)
      this.drawChart3L(combinedFilter)
      this.drawChart3R(combinedFilter)
    },
    drawCallouts: async function (combinedFilter) {
      this.callouts.callout1.loading = true

      this.callouts.callout2.loading = this.callouts.callout3.loading = true

      let response = await this.$api.data.getAccountPerformance(combinedFilter)

      this.callouts.callout1.loading = false

      let result = this.drawCallout1(response)

      if (!result) {
        this.callouts.callout2.loading = this.callouts.callout3.loading = false
      }

      //this.callouts.callout2.loading = this.callouts.callout3.loading = true;

      response = await this.$api.data.getAccountBestWorstProducts(
        combinedFilter
      )

      this.callouts.callout2.loading = this.callouts.callout3.loading = false

      this.drawCallout23(response)
    },
    drawCallout1: function (response) {
      if (response.length > 0) {
        this.callouts.callout1.value = this.callouts.callout2.value = this.callouts.callout3.value =
          ''
        this.callouts.callout1.header = this.callouts.callout2.header = this.callouts.callout3.header =
          ''
        this.callouts.callout1.subheader = this.callouts.callout2.subheader = this.callouts.callout3.subheader =
          ''

        let res = response[0]
        this.callouts.callout1.value = parseFloat(res.delta * 100).toFixed(2)
        let period =
          moment(res.p1_period_start).format('MMM, YY') ===
          moment(res.p1_period_end).format('MMM, YY')
            ? moment(res.p1_period_start).format('MMM, YY')
            : `${moment(res.p1_period_start).format('MMM, YY')} - ${moment(
                res.p1_period_end
              ).format('MMM, YY')}`

        this.callouts.callout1.footer = `Compared to average sales in ${period}`
        this.callouts.callout1.header = ''
        this.callouts.callout1.postfix = '%'

        //2) If no sales during both selection and reference period, color box grey and show "No recorded sales [beginning of reference period] - [end of selection period]".
        // Color product boxes grey as well and show same message
        if (parseFloat(res.p1total) === 0 && parseFloat(res.p2total) === 0) {
          //this.callouts.callout1.bgColorClass = 'bg-secondary'
          this.callouts.callout1.value = this.callouts.callout2.value = this.callouts.callout3.value =
            ''
          this.callouts.callout1.header = ''
          this.callouts.callout2.header = 'Best product sales growth:'
          this.callouts.callout3.header = 'Worst product sales growth:'
          this.callouts.callout1.subheader = this.callouts.callout2.subheader = this.callouts.callout3.subheader =
            'No recorded sales'
          period = `${moment(res.p2_period_start).format('MMM, YY')} - ${moment(
            res.p1_period_end
          ).format('MMM, YY')}`
          this.callouts.callout1.footer = this.callouts.callout2.footer = this.callouts.callout3.footer = `${period}`

          return false
        }

        //1) If no sales during either selection or reference period, show absolute ($) change
        if (parseFloat(res.p1total) === 0 || parseFloat(res.p2total) === 0) {
          let delta = Math.abs(
            parseFloat(res.p2total) - parseFloat(res.p1total)
          ).toFixed(0)
          this.callouts.callout1.value = delta
          this.callouts.callout1.hideSign = true
          this.callouts.callout1.postfix = '$'
          this.callouts.callout1.header = 'Sales change:'
          //this.callouts.callout1.bgColorClass = 'bg-secondary'
        }
      } else {
        //this.callouts.callout1.value = '';
        return false
      }

      return true
    },
    drawCallout23: function (response) {
      if (response.length > 0) {
        const max = response.reduce(function (prev, current) {
          return parseFloat(prev.dif) > parseFloat(current.dif) ? prev : current
        })

        const min = response.reduce(function (prev, current) {
          return parseFloat(prev.dif) < parseFloat(current.dif) ? prev : current
        })

        let period =
          moment(max.p1_period_start).format('MMM, YY') ===
          moment(max.p1_period_end).format('MMM, YY')
            ? moment(max.p1_period_start).format('MMM, YY')
            : `${moment(max.p1_period_start).format('MMM, YY')} - ${moment(
                max.p1_period_end
              ).format('MMM, YY')}`

        this.callouts.callout2.value = parseFloat(max.dif).toFixed(2)
        this.callouts.callout2.header = 'Best product sales growth:'
        this.callouts.callout2.subheader = `${max.productname}`
        this.callouts.callout2.footer = `compared to ${period}`

        this.callouts.callout3.value = parseFloat(min.dif).toFixed(2)
        this.callouts.callout3.header = 'Worst product sales growth:'
        this.callouts.callout3.subheader = `${min.productname}`
        this.callouts.callout3.footer = `compared to ${period}`

        //If only one product sold (and thus had both best/worst growth),
        //grey out worst box if positive growth, or best box if negative growth
        if (response.length === 1) {
          let res = response[0]

          if (parseFloat(res.dif) > 0) {
            this.callouts.callout3.header = 'Worst product sales growth:'
            this.callouts.callout3.subheader = 'N/A'
            this.callouts.callout3.value = ''
            period = `${moment(res.p2_period_start).format(
              'MMM, YY'
            )} - ${moment(res.p1_period_end).format('MMM, YY')}`
            this.callouts.callout3.footer = `${period}`
          }

          if (parseFloat(res.dif) < 0) {
            this.callouts.callout2.header = 'Best product sales growth:'
            this.callouts.callout2.subheader = 'N/A'
            this.callouts.callout2.value = ''
            period = `${moment(res.p2_period_start).format(
              'MMM, YY'
            )} - ${moment(res.p1_period_end).format('MMM, YY')}`
            this.callouts.callout2.footer = `${period}`
          }
        }
      } else {
        this.callouts.callout2.value = ''
        this.callouts.callout2.header = ''
        this.callouts.callout2.footer = ''

        this.callouts.callout3.value = ''
        this.callouts.callout3.header = ''
        this.callouts.callout3.footer = ''
      }
    },
    drawAccountDetails: function (combinedFilter) {
      this.accountDetails.accountName = ''
      this.accountDetails.firstOrder = ''
      this.accountDetails.avgInvoice = ''
      this.accountDetails.lastInvoice = ''
      this.accountDetails.nextInvoice = ''
      this.accountDetails.avgPeriod = ''
      this.accountDetails.regularity = ''
      this.accountDetails.invCount = ''
      this.accountDetails.chain = ''
      this.accountDetails.premise = ''
      this.accountDetails.street = ''
      this.accountDetails.city = ''
      this.accountDetails.state = ''
      this.accountDetails.code = ''
      this.accountDetails.phone = ''

      this.$api.data.getAccountDetails(combinedFilter).then(response => {
        console.log('getAccountDetails', response)

        if (response.length === 1) {
          let row = response[0]

          this.accountDetails.accountName = row.accountname
          this.accountDetails.firstOrder = row.firstorder
          this.accountDetails.avgInvoice =
            '$' + parseFloat(row.averageorder).toFixed(2)
          this.accountDetails.lastInvoice = row.lastorder
          this.accountDetails.nextInvoice = row.predictednext
          this.accountDetails.avgPeriod = parseFloat(row.avgperiod).toFixed(0)
          this.accountDetails.regularity = row.regularity
          this.accountDetails.invCount = parseFloat(row.ordercount).toFixed(0)
          this.accountDetails.chain = row.chainname
          this.accountDetails.premise = row.premise
          this.accountDetails.street = row.bill_street
          this.accountDetails.city = row.bill_city
          this.accountDetails.state = row.bill_state
          this.accountDetails.code = row.bill_code
          this.accountDetails.phone = row.phone
        }
      })

      this.$api.data['Account.Contacts'](combinedFilter).then(response => {
        this.accountDetails.contacts = []
        if (response.length > 0) {
          this.accountDetails.contacts = response
        }
      })
    },

    getAccountSalesByProductChartData: function () {
      let filter1 = this.$refs.filteringPanel.selected
      let filter2 = this.$refs.filterPanelSalesByProduct.selected

      let combinedFilter = {
        ...filter1
      }

      combinedFilter.accountname = combinedFilter.account
        ? combinedFilter.account.label
        : ''

      combinedFilter.productcategory = filter2.productcategory

      this.drawChart3L(combinedFilter)
      this.drawChart3R(combinedFilter)
    }
  }
}
</script>

<style></style>
