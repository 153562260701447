export default {
  chart1LTable: {
    name: 'custom_table_account_overview_chart1L',
    isLoading: false,
    dataSet: [],
    options: {
      columns: ['Year', 'Month', 'Activities', '$ Sold'],

      totalColumns: [
        {
          name: '$ Sold',
          alias: 'Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ],
      filterable: ['Year', 'Month', 'Activities', '$ Sold'],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,

      formatColumns: [
        {
          name: 'Year',
          type: 'number'
        },
        {
          name: 'Month',
          type: 'month' //used for correct sorting
        },
        {
          name: 'Off Premise',
          type: 'number',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        },
        {
          name: 'On Premise',
          type: 'number',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        },
        {
          name: '$ Sold',
          type: 'number',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ]
    }
  },
  chart1RTable: {
    name: 'custom_table_account_overview_chart1R',
    isLoading: false,
    dataSet: [],
    options: {
      columns: ['Year', 'Month', 'Activities', '$ Sold'],

      totalColumns: [
        {
          name: '$ Sold',
          alias: 'Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ],
      filterable: ['Year', 'Month', 'Activities', '$ Sold'],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,

      formatColumns: [
        {
          name: 'Year',
          type: 'number'
        },
        {
          name: 'Month',
          type: 'month' //used for correct sorting
        },
        {
          name: 'Off Premise',
          type: 'number',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        },
        {
          name: 'On Premise',
          type: 'number',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        },
        {
          name: '$ Sold',
          type: 'number',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ]
    }
  },
  chart2LTable: {
    name: 'custom_table_account_overview_chart2L',
    isLoading: false,
    dataSet: [],
    options: {
      columns: ['Year', 'Month', 'Product Category', '$ Sold'],
      totalColumns: [
        {
          name: '$ Sold',
          alias: 'Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ],
      filterable: ['Year', 'Month', 'Product Category', '$ Sold'],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,

      formatColumns: [
        {
          name: 'Year',
          type: 'number'
        },
        {
          name: 'Month',
          type: 'month' //used for correct sorting
        },
        {
          name: 'Product Category',
          type: 'string'
        },
        {
          name: '$ Sold',
          type: 'number',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ]
    }
  },
  chart2RTable: {
    name: 'custom_table_account_overview_chart2R',
    isLoading: false,
    dataSet: [],
    options: {
      columns: ['Year', 'Month', 'Product Category', '$ Sold'],
      totalColumns: [
        {
          name: '$ Sold',
          alias: 'Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ],
      filterable: ['Year', 'Month', 'Product Category', '$ Sold'],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,

      formatColumns: [
        {
          name: 'Year',
          type: 'number'
        },
        {
          name: 'Month',
          type: 'month' //used for correct sorting
        },
        {
          name: 'Product Category',
          type: 'string'
        },
        {
          name: '$ Sold',
          type: 'number',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ]
    }
  },
  chart3LTable: {
    name: 'custom_table_account_overview_chart3L',
    isLoading: false,
    dataSet: [],
    options: {
      columns: ['Year', 'Month', 'Product', '$ Sold'],
      totalColumns: [
        {
          name: '$ Sold',
          alias: 'Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ],
      filterable: ['Year', 'Month', 'Product', '$ Sold'],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,

      formatColumns: [
        {
          name: 'Year',
          type: 'number'
        },
        {
          name: 'Month',
          type: 'month' //used for correct sorting
        },
        {
          name: 'Product',
          type: 'string'
        },
        {
          name: '$ Sold',
          type: 'number',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ]
    }
  },
  chart3RTable: {
    name: 'custom_table_account_overview_chart3R',
    isLoading: false,
    dataSet: [],
    options: {
      columns: ['Year', 'Month', 'Product', '$ Sold'],
      totalColumns: [
        {
          name: '$ Sold',
          alias: 'Sold',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ],
      filterable: ['Year', 'Month', 'Product', '$ Sold'],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,

      formatColumns: [
        {
          name: 'Year',
          type: 'number'
        },
        {
          name: 'Month',
          type: 'month' //used for correct sorting
        },
        {
          name: 'Product',
          type: 'string'
        },
        {
          name: '$ Sold',
          type: 'number',
          style: {
            style: 'currency',
            currency: 'USD'
          }
        }
      ]
    }
  }
}
