import moment from 'moment'

export default {
  drawChart1L: function (combinedFilter) {
    this.charts.chart1L.loading = true

    this.charts.chart1L.subtitle = `Total $USD spend, total touchpoint count in the period [${moment(
      combinedFilter.period.startDate
    ).format('YYYY-MM-DD')} - ${moment(combinedFilter.period.endDate).format(
      'YYYY-MM-DD'
    )}]`

    this.$api.data.getAccountSalesAndActivity(combinedFilter).then(response => {
      this.charts.chart1L.loading = false

      if (response.length === 0) return

      this.charts.chart1L.rawData = response

      //chart 1
      let headers = [['Year', 'Total Sales', 'Activities']]

      let data = [
        ...response.map(item => {
          return [
            new Date(moment(item.date).format('MM/DD/YY')),
            parseFloat(item.total || 0),
            parseFloat(item.activities || 0)
          ]
        })
      ]

      this.charts.chart1L.chartData = headers.concat(data)
    })
  },
  onChart1LTableMount () {
    console.log('onChart1LTableMount', this.charts.chart1L.rawData)

    this.tables.chart1LTable.dataSet = this.charts.chart1L.rawData.map(i => ({
      Year: moment(i.date).format('YYYY'),
      Month: moment(i.date).format('MMMM'),
      Activities: i.activities,
      '$ Sold': i.total
    }))
  },

  drilldownChart1L: function (e) {
    if (e.selection.column === 1) {
      //sales
      this.salesDrilldownChart1L(e)
    }

    if (e.selection.column === 2) {
      //activities
      this.activityDrilldownChart1L(e)
    }
  },
  salesDrilldownChart1L: function (e) {
    let filter = this.$refs.filteringPanel.selected

    //get selected chart elements
    let date = e.data[e.selection.row + 1][0]

    filter.period.startDate = date
    filter.period.endDate = moment(date).endOf('month')

    filter.country.name = ''
    filter.state = ''
    //SalesFullReport uses multiselected filters
    //filter.countries = filter.country.name ? [filter.country.name] : []
    //filter.states = filter.state ? [filter.state] : []
    filter.accounts = filter.accountname ? [filter.accountname] : []

    let description = `${this.$refs.chart1L.title} [Period: ${moment(
      filter.period.startDate
    ).format('DD MMM, YY')} - ${moment(filter.period.endDate).format(
      'DD MMM, YY'
    )}; Market: ${filter.state ? filter.state : 'All'}; Account: ${
      filter.accountname ? filter.accountname : 'All'
    }]`

    this.$store.dispatch('drilldown.save', {
      filter: filter,
      parent: this.$route.name,
      description: description,
      method: 'Sales.InvoiceData',
      formatColumns: ['Spent']
    })
    console.log('chart1L.drilldown.filter', filter)
    //open new page
    this.$router.push({
      name: 'Chart drilldown'
    })
  },
  activityDrilldownChart1L: function (e) {
    //let reportid = uuidv4()
    let filter = this.$refs.filteringPanel.selected

    //get selected chart elements
    filter.category = '' //e.data[0][e.selection.column]
    filter.activitydate = moment(
      new Date(e.data[e.selection.row + 1][0])
    ).format('YYYY-MM-DD')

    //save data to storage
    let description = `${this.$refs.chart1L.title} [Activity Month End: ${filter.activitydate}]`
    this.$store.dispatch('drilldown.save', {
      filter: filter,
      parent: this.$route.name,
      description: description,
      method: 'SalesTeam.MonthlyActivityDrilldown'
    })

    console.log('chart1L.activityDrilldownChart1L.filter', {
      filter: filter,
      parent: this.$route.name,
      description: description,
      method: 'SalesTeam.MonthlyActivityDrilldown'
    })

    //open new page
    //api.data.SalesTeam.WeeklyActivityDrilldown(filter)
    this.$router.push({
      name: 'Chart drilldown'
    })
  }
}
