export default {
  callout1: {
    loading: true,
    value: '',
    header: '',
    subheader: '',
    footer: '',
    postfix: '%',
    bgColorClass: ''
  },
  callout2: {
    loading: false,
    value: '',
    header: '',
    subheader: '',
    footer: '',
    postfix: '$',
    bgColorClass: ''
  },
  callout3: {
    loading: false,
    value: '',
    header: '',
    subheader: '',
    footer: '',
    postfix: '$',
    bgColorClass: ''
  },
  periodPrev: ''
}
