var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('h2', [_vm._v("Account Overview - Distribution")]), _c('br'), _c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "dataset-name": "distribution-accounts-overview",
      "show-labels": true,
      "load-dictionaries": _vm.loadDictionaries,
      "districts": _vm.filteringPanel.districts,
      "filters": _vm.filteringPanel.filters,
      "loaded": _vm.filteringPanel.loaded,
      "compact": false
    },
    on: {
      "search": _vm.getData,
      "change": _vm.onFilteringPanelChange,
      "async-search": _vm.onAsyncSearch,
      "dict-loaded": _vm.onFilteringPanelLoad,
      "custom-filter-selected": _vm.onCustomFilterSelected,
      "state-changed": _vm.onFilteringPanelStateChange
    }
  }), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _vm.dataSection.visible ? [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "lg": "6",
      "align": "left"
    }
  }, [_c('b-row', [_c('b-col', [_c('h3', [_c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "5"
    }
  }, [_vm._v(" Account name: ")]), _c('b-col', [_c('small', [_vm._v(_vm._s(_vm.accountDetails.accountName))])])], 1), _c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "5"
    }
  }, [_vm._v(" Address: ")]), _c('b-col', [_c('small', [_vm._v(_vm._s(_vm.accountDetails.street))])])], 1), _c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "5"
    }
  }), _c('b-col', [_c('small', [_vm._v(_vm._s(_vm.accountDetails.city) + " " + _vm._s(_vm.accountDetails.state) + " " + _vm._s(_vm.accountDetails.code))])])], 1), _c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "5"
    }
  }), _c('b-col', [_c('small', [_vm._v(_vm._s(_vm.accountDetails.phone))])])], 1), _vm.accountDetails.chain !== 'NONE' ? _c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "5"
    }
  }, [_vm._v(" Chain: ")]), _c('b-col', [_c('small', [_vm._v(_vm._s(_vm.accountDetails.chain))])])], 1) : _vm._e(), _c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "5"
    }
  }, [_vm._v(" Premise: ")]), _c('b-col', [_c('small', [_vm._v(_vm._s(_vm.accountDetails.premise))])])], 1), _c('b-row', {
    staticClass: "p-2"
  }, [_vm.accountDetails.contacts.length > 0 ? _c('b-col', {
    attrs: {
      "lg": "5"
    }
  }, [_vm._v(" Contacts: ")]) : _vm._e(), _c('b-col', [_vm._l(_vm.accountDetails.contacts, function (contact, index) {
    return [_c('b-badge', {
      key: "bb-".concat(_vm.uid, "-").concat(index),
      staticClass: "m-1",
      attrs: {
        "variant": "dark",
        "id": "bbt-".concat(contact.contactid)
      }
    }, [_vm._v(" " + _vm._s(contact.contactname) + " ")]), _c('b-popover', {
      key: "po-".concat(_vm.uid, "-").concat(index),
      attrs: {
        "target": "bbt-".concat(contact.contactid),
        "triggers": "hover",
        "placement": "top"
      }
    }, [_c('ul', [_c('li', [_vm._v("Name: " + _vm._s(contact.contactname))]), _c('li', [_vm._v("Email: " + _vm._s(contact.email))]), _c('li', [_vm._v("Phone: " + _vm._s(contact.phone))]), _c('li', [_vm._v("Title: " + _vm._s(contact.title))])])])];
  })], 2)], 1), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('div', [_c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "5"
    }
  }, [_vm._v(" First order: ")]), _c('b-col', [_c('small', [_vm._v(_vm._s(_vm.accountDetails.firstOrder))])])], 1), _c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "5"
    }
  }, [_vm._v(" Average invoice: ")]), _c('b-col', [_c('small', [_vm._v(_vm._s(_vm.accountDetails.avgInvoice))])])], 1), _c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "5"
    }
  }, [_vm._v(" Invoice count: ")]), _c('b-col', [_c('small', [_vm._v(_vm._s(_vm.accountDetails.invCount))])])], 1), _c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "5"
    }
  }, [_vm._v(" Estimated days between invoices: ")]), _c('b-col', [_c('small', [_vm._v(_vm._s(_vm.accountDetails.avgPeriod))])])], 1), _c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "5"
    }
  }, [_vm._v(" Last invoice: ")]), _c('b-col', [_c('small', [_vm._v(_vm._s(_vm.accountDetails.lastInvoice))])])], 1), _c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "5"
    }
  }, [_vm._v(" Estimated next invoice: ")]), _c('b-col', [_c('small', [_vm._v(_vm._s(_vm.accountDetails.nextInvoice))])])], 1), _c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "5"
    }
  }, [_vm._v(" Invoice regularity status: ")]), _c('b-col', [_c('small', [_vm._v(_vm._s(_vm.accountDetails.regularity))])])], 1)], 1)], 1)])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "lg": "6",
      "align": "center"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "lg": "12",
      "align": "center"
    }
  }, [_c('callout-box', {
    ref: "callout1",
    attrs: {
      "loading": _vm.callouts.callout1.loading,
      "postfix": _vm.callouts.callout1.postfix,
      "prefix": _vm.callouts.callout1.prefix,
      "hide-sign": _vm.callouts.callout1.hideSign,
      "bg-color-class": _vm.callouts.callout1.bgColorClass,
      "width": '95%',
      "value": _vm.callouts.callout1.value,
      "header": _vm.callouts.callout1.header,
      "subheader": _vm.callouts.callout1.subheader,
      "footer": _vm.callouts.callout1.footer
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "6",
      "lg": "6",
      "align": "center"
    }
  }, [_c('callout-box', {
    ref: "callout2",
    attrs: {
      "loading": _vm.callouts.callout2.loading,
      "postfix": _vm.callouts.callout2.postfix,
      "prefix": _vm.callouts.callout2.prefix,
      "hide-sign": _vm.callouts.callout2.hideSign,
      "bg-color-class": _vm.callouts.callout2.bgColorClass,
      "width": '90%',
      "value": _vm.callouts.callout2.value,
      "header": _vm.callouts.callout2.header,
      "subheader": _vm.callouts.callout2.subheader,
      "footer": _vm.callouts.callout2.footer
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "lg": "6",
      "align": "center"
    }
  }, [_c('callout-box', {
    ref: "callout3",
    attrs: {
      "loading": _vm.callouts.callout3.loading,
      "postfix": _vm.callouts.callout3.postfix,
      "prefix": _vm.callouts.callout3.prefix,
      "hide-sign": _vm.callouts.callout3.hideSign,
      "bg-color-class": _vm.callouts.callout3.bgColorClass,
      "width": '90%',
      "value": _vm.callouts.callout3.value,
      "header": _vm.callouts.callout3.header,
      "subheader": _vm.callouts.callout3.subheader,
      "footer": _vm.callouts.callout3.footer
    }
  })], 1)], 1)], 1)], 1), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('b-row', [_c('b-col', [_c('g-chart-custom', {
    ref: "chart1L",
    attrs: {
      "default-mode": _vm.charts.chart1L.isDefaultMode,
      "title": "Historical sales and activity",
      "subtitle": _vm.charts.chart1L.subtitle,
      "type": "ComboChart",
      "loading": _vm.charts.chart1L.loading,
      "data": _vm.charts.chart1L.chartData,
      "options": _vm.charts.chart1L.chartOptions
    },
    on: {
      "chart-element-selected": _vm.drilldownChart1L
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart1L-ddi",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('i', {
          staticClass: "ml-2 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart1L-ddi"
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col'), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart1L.isDefaultMode,
            expression: "charts.chart1L.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart1L.isDefaultMode = !_vm.charts.chart1L.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart1L.isDefaultMode,
            expression: "!charts.chart1L.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart1L.isDefaultMode = !_vm.charts.chart1L.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "id": "chart1LTable-".concat(_vm.uid),
            "name": _vm.tables.chart1LTable.name,
            "loading": _vm.tables.chart1LTable.isLoading,
            "data": _vm.tables.chart1LTable.dataSet,
            "options": _vm.tables.chart1LTable.options
          },
          on: {
            "mounted": _vm.onChart1LTableMount
          }
        })];
      },
      proxy: true
    }], null, false, 2106315040)
  })], 1), _c('b-col', [_c('g-chart-custom', {
    ref: "chart1R",
    attrs: {
      "default-mode": _vm.charts.chart1R.isDefaultMode,
      "title": "Historical sales and activity",
      "subtitle": _vm.charts.chart1R.subtitle,
      "type": "ComboChart",
      "loading": _vm.charts.chart1R.loading,
      "data": _vm.charts.chart1R.chartData,
      "options": _vm.charts.chart1R.chartOptions
    },
    on: {
      "chart-element-selected": _vm.drilldownChart1R
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart1R-ddi",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('i', {
          staticClass: "ml-2 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart1R-ddi"
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col'), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart1R.isDefaultMode,
            expression: "charts.chart1R.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart1R.isDefaultMode = !_vm.charts.chart1R.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart1R.isDefaultMode,
            expression: "!charts.chart1R.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart1R.isDefaultMode = !_vm.charts.chart1R.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "id": "chart1RTable-".concat(_vm.uid),
            "name": _vm.tables.chart1RTable.name,
            "loading": _vm.tables.chart1RTable.isLoading,
            "data": _vm.tables.chart1RTable.dataSet,
            "options": _vm.tables.chart1RTable.options
          },
          on: {
            "mounted": _vm.onChart1RTableMount
          }
        })];
      },
      proxy: true
    }], null, false, 1295247392)
  })], 1)], 1), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('b-row', [_c('b-col', [_c('g-chart-custom', {
    ref: "chart2L",
    attrs: {
      "default-mode": _vm.charts.chart2L.isDefaultMode,
      "title": "Sales by product category",
      "subtitle": _vm.charts.chart2L.subtitle,
      "type": "BarChart",
      "loading": _vm.charts.chart2L.loading,
      "data": _vm.charts.chart2L.chartData,
      "options": _vm.charts.chart2L.chartOptions
    },
    on: {
      "chart-element-selected": _vm.drilldownChart2L
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart2L-ddi",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('i', {
          staticClass: "ml-2 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart2L-ddi"
          }
        })];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.tables.chart2LTable.name,
            "loading": _vm.tables.chart2LTable.isLoading,
            "data": _vm.tables.chart2LTable.dataSet,
            "options": _vm.tables.chart2LTable.options
          },
          on: {
            "mounted": _vm.onChart2LTableMount
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col'), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart2L.isDefaultMode,
            expression: "charts.chart2L.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart2L.isDefaultMode = !_vm.charts.chart2L.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart2L.isDefaultMode,
            expression: "!charts.chart2L.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart2L.isDefaultMode = !_vm.charts.chart2L.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }], null, false, 4272946016)
  })], 1), _c('b-col', [_c('g-chart-custom', {
    ref: "chart2R",
    attrs: {
      "default-mode": _vm.charts.chart2R.isDefaultMode,
      "title": "Sales by product category",
      "subtitle": _vm.charts.chart2R.subtitle,
      "type": "BarChart",
      "loading": _vm.charts.chart2R.loading,
      "data": _vm.charts.chart2R.chartData,
      "options": _vm.charts.chart2R.chartOptions
    },
    on: {
      "chart-element-selected": _vm.drilldownChart2R
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart2R-ddi",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('i', {
          staticClass: "ml-2 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart2R-ddi"
          }
        })];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.tables.chart2RTable.name,
            "loading": _vm.tables.chart2RTable.isLoading,
            "data": _vm.tables.chart2RTable.dataSet,
            "options": _vm.tables.chart2RTable.options
          },
          on: {
            "mounted": _vm.onChart2RTableMount
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col'), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart2R.isDefaultMode,
            expression: "charts.chart2R.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart2R.isDefaultMode = !_vm.charts.chart2R.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart2R.isDefaultMode,
            expression: "!charts.chart2R.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart2R.isDefaultMode = !_vm.charts.chart2R.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }], null, false, 1846312190)
  })], 1)], 1), _c('hr'), _c('br'), _c('br'), _c('br'), _c('br'), _c('hr'), _c('b-row', [_c('b-col', [_c('g-chart-custom', {
    ref: "chart3L",
    attrs: {
      "default-mode": _vm.charts.chart3L.isDefaultMode,
      "title": "Sales by product",
      "subtitle": _vm.charts.chart3L.subtitle,
      "type": "BarChart",
      "loading": _vm.charts.chart3L.loading,
      "data": _vm.charts.chart3L.chartData,
      "options": _vm.charts.chart3L.chartOptions
    },
    on: {
      "chart-element-selected": _vm.drilldownChart3L
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart3L-ddi",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('i', {
          staticClass: "ml-2 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart3L-ddi"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('filter-panel', {
          ref: "filterPanelSalesByProduct",
          attrs: {
            "show-countries": false,
            "show-states": false,
            "show-product-categories": true,
            "show-date-range": false
          },
          on: {
            "search-triggered": _vm.getAccountSalesByProductChartData
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col'), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart3L.isDefaultMode,
            expression: "charts.chart3L.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart3L.isDefaultMode = !_vm.charts.chart3L.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart3L.isDefaultMode,
            expression: "!charts.chart3L.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart3L.isDefaultMode = !_vm.charts.chart3L.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.tables.chart3LTable.name,
            "loading": _vm.tables.chart3LTable.isLoading,
            "data": _vm.tables.chart3LTable.dataSet,
            "options": _vm.tables.chart3LTable.options
          },
          on: {
            "mounted": _vm.onChart3LTableMount
          }
        })];
      },
      proxy: true
    }], null, false, 249516605)
  })], 1), _c('b-col', [_c('g-chart-custom', {
    ref: "chart3R",
    attrs: {
      "default-mode": _vm.charts.chart3R.isDefaultMode,
      "title": "Sales by product",
      "subtitle": _vm.charts.chart3R.subtitle,
      "type": "BarChart",
      "loading": _vm.charts.chart3R.loading,
      "data": _vm.charts.chart3R.chartData,
      "options": _vm.charts.chart3R.chartOptions
    },
    on: {
      "chart-element-selected": _vm.drilldownChart3R
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('b-popover', {
          attrs: {
            "target": "chart3R-ddi",
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', [_vm._v("Chart drilldown available")])]), _c('i', {
          staticClass: "ml-2 fa fa-share fa-rotate-90",
          attrs: {
            "id": "chart3R-ddi"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('filter-panel', {
          ref: "filterPanelSalesByProduct2",
          attrs: {
            "show-countries": false,
            "show-states": false,
            "show-product-categories": true,
            "show-date-range": false
          },
          on: {
            "search-triggered": _vm.getAccountSalesByProductChartData
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col'), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart3R.isDefaultMode,
            expression: "charts.chart3R.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart3R.isDefaultMode = !_vm.charts.chart3R.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart3R.isDefaultMode,
            expression: "!charts.chart3R.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart3R.isDefaultMode = !_vm.charts.chart3R.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.tables.chart3RTable.name,
            "loading": _vm.tables.chart3RTable.isLoading,
            "data": _vm.tables.chart3RTable.dataSet,
            "options": _vm.tables.chart3RTable.options
          },
          on: {
            "mounted": _vm.onChart3RTableMount
          }
        })];
      },
      proxy: true
    }], null, false, 1828800753)
  })], 1)], 1)] : _vm._e(), _c('hr')], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }