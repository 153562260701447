import moment from 'moment'

export default {
  drawChart3L: function (combinedFilter) {
    console.log('combinedFilter', JSON.stringify(combinedFilter))

    this.charts.chart3L.loading = true

    this.charts.chart3L.subtitle = `Percent of total $USD spend in the period [${moment(
      combinedFilter.period.startDate
    ).format('YYYY-MM-DD')} - ${moment(combinedFilter.period.endDate).format(
      'YYYY-MM-DD'
    )}] `

    this.$api.data.getAccountSalesByProduct(combinedFilter).then(response => {
      this.charts.chart3L.loading = false

      if (response.length === 0) return

      this.charts.chart3L.rawData = response

      let data = [
        ...response.map(item => {
          return [
            new Date(item.date),
            parseFloat(item.total || 0),
            item.productname
          ]
        })
      ]

      let pivot_data = this.$helpers.getPivotArray(data, 0, 2, 1, false)

      //no less then 3 rows for this chart type
      if (pivot_data.length < 3) return

      this.charts.chart3L.chartData = pivot_data
    })
  },
  drilldownChart3L: function (e) {
    let filter = JSON.parse(JSON.stringify(this.$refs.filteringPanel.selected))
    //get selected chart elements
    filter.product = e.data[0][e.selection.column]

    let date = e.data[e.selection.row + 1][0]
    filter.period.startDate = date
    filter.period.endDate = moment(date).endOf('month')

    //filter.country.name = ''
    //filter.state = ''
    //SalesFullReport uses multiselected filters
    //filter.countries = filter.country.name ? [filter.country.name] : []
    //filter.states = filter.state ? [filter.state] : []
    filter.products = filter.product ? [filter.product] : []

    filter.accounts = filter.accountname ? [filter.accountname] : []

    let description = `${this.$refs.chart3L.title} [Period: ${moment(
      filter.period.startDate
    ).format('DD MMM, YY')} - ${moment(filter.period.endDate).format(
      'DD MMM, YY'
    )}; Market: ${filter.state ? filter.state : 'All'}; Product: ${
      filter.product ? filter.product : 'All'
    }; Account: ${filter.accountname ? filter.accountname : 'All'}]`

    this.$store.dispatch('drilldown.save', {
      filter: filter,
      parent: this.$route.name,
      description: description,
      method: 'Sales.InvoiceData',
      formatColumns: ['Spent']
    })

    //open new page
    this.$router.push({
      name: 'Chart drilldown'
    })
  },
  onChart3LTableMount () {
    console.log('onChart3LTableMount', this.charts.chart3L.rawData)

    this.tables.chart3LTable.dataSet = this.charts.chart3L.rawData.map(i => ({
      Year: moment(i.date).format('YYYY'),
      Month: moment(i.date).format('MMMM'),
      Product: i.productname,
      '$ Sold': i.total
    }))
  }
}
