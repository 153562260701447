export default {
  chart1L: {
    isDefaultMode: true,
    loading: false,
    rawData: [],
    chartData: null,
    chartOptions: {
      height: 350,
      chartArea: {
        width: '80%',
        height: '75%'
      },
      title: '',
      legend: {
        position: 'bottom'
      },
      vAxes: {
        0: {
          title: 'Total Sales'
        },
        1: {
          title: 'Touchpoints',
          minValue: 0
        }
      },

      hAxis: {
        //     format: 'MMM, YY',
      },
      seriesType: 'bars',
      series: {
        0: {
          type: 'line',
          targetAxisIndex: 0
        },
        1: {
          targetAxisIndex: 1
        },
        2: {
          targetAxisIndex: 1
        },
        3: {
          targetAxisIndex: 1
        },
        4: {
          targetAxisIndex: 1
        }
      }
    }
  },
  chart1R: {
    isDefaultMode: true,
    loading: false,
    rawData: [],
    chartData: null,
    chartOptions: {
      height: 350,
      chartArea: {
        width: '80%',
        height: '75%'
      },
      title: '',
      legend: {
        position: 'bottom'
      },
      vAxes: {
        0: {
          title: 'Total Sales'
        },
        1: {
          title: 'Touchpoints',
          minValue: 0
        }
      },

      hAxis: {
        //     format: 'MMM, YY',
      },
      seriesType: 'bars',
      series: {
        0: {
          type: 'line',
          targetAxisIndex: 0
        },
        1: {
          targetAxisIndex: 1
        },
        2: {
          targetAxisIndex: 1
        },
        3: {
          targetAxisIndex: 1
        },
        4: {
          targetAxisIndex: 1
        }
      }
    }
  },
  chart2L: {
    isDefaultMode: true,
    loading: false,
    rawData: [],
    chartData: null,
    chartOptions: {
      height: 300,
      chartArea: {
        width: '80%',
        height: '80%'
      },
      isStacked: 'percent',
      hAxis: {
        //     format: 'MMM, YY',
      }
      /*
                                series: {
                                    0: {
                                        color: 'green',
                                    },                        
                                    1: {
                                        color: 'yellow',
                                    },      

                                //colors: ['green','blue', 'red','yellow','LightGreen','purple'],
                                },
                                */
    }
  },
  chart2R: {
    isDefaultMode: true,
    loading: false,
    rawData: [],
    chartData: null,
    chartOptions: {
      height: 300,
      chartArea: {
        width: '80%',
        height: '80%'
      },
      isStacked: 'percent',
      hAxis: {
        //     format: 'MMM, YY',
      }
      /*
                                series: {
                                    0: {
                                        color: 'green',
                                    },                        
                                    1: {
                                        color: 'yellow',
                                    },      

                                //colors: ['green','blue', 'red','yellow','LightGreen','purple'],
                                },
                                */
    }
  },
  chart3L: {
    isDefaultMode: true,
    loading: false,
    rawData: [],
    chartData: null,
    chartOptions: {
      height: 300,
      chartArea: {
        width: '80%',
        height: '80%'
      },
      isStacked: 'percent',
      hAxis: {
        //    format: 'MMM, YY',
      }
    }
  },
  chart3R: {
    isDefaultMode: true,
    loading: false,
    rawData: [],
    chartData: null,
    chartOptions: {
      height: 300,
      chartArea: {
        width: '80%',
        height: '80%'
      },
      isStacked: 'percent',
      hAxis: {
        //    format: 'MMM, YY',
      }
    }
  }
}
