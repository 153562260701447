import moment from 'moment'

export default {
  drawChart2L: function (combinedFilter) {
    this.charts.chart2L.loading = true

    this.charts.chart2L.subtitle = `Percent of total $USD spend in the period [${moment(
      combinedFilter.period.startDate
    ).format('YYYY-MM-DD')} - ${moment(combinedFilter.period.endDate).format(
      'YYYY-MM-DD'
    )}]`

    this.$api.data
      .getAccountProductCategorySales(combinedFilter)
      .then(response => {
        this.charts.chart2L.loading = false

        if (response.length === 0) return

        this.charts.chart2L.rawData = response
        /*
                  let headers = [
                      ["Year", "Total Sales", "Activities"]
                  ];
  */
        let data = [
          ...response.map(item => {
            return [
              new Date(item.date),
              parseFloat(item.total || 0),
              item.productcategory
            ]
          })
        ]

        let pivot_data = this.$helpers.getPivotArray(data, 0, 2, 1, false)

        //set right color order for categories
        let colors = []
        pivot_data[0].forEach(element => {
          if (element === 'Whiskey') colors.push('green')
          if (element === 'Gin') colors.push('blue')
          if (element === 'Liqueur') colors.push('red')
          if (element === 'Vodka') colors.push('yellow')
          if (element === 'White Whiskey') colors.push('LightGreen')
          if (element === 'Specialty') colors.push('purple')
        })

        this.charts.chart2L.chartOptions.colors = colors

        //no less then 3 rows for this chart type
        if (pivot_data.length < 3) return

        this.charts.chart2L.chartData = pivot_data
      })
  },
  drilldownChart2L: function (e) {
    let filter = JSON.parse(JSON.stringify(this.$refs.filteringPanel.selected))

    //get selected chart elements
    filter.productcategory = e.data[0][e.selection.column]

    let date = e.data[e.selection.row + 1][0]
    filter.period.startDate = date
    filter.period.endDate = moment(date).endOf('month')

    //filter.country.name = ''
    //filter.state = ''

    //SalesFullReport uses multiselected filters
    //filter.countries = filter.country.name ? [filter.country.name] : []
    //filter.states = filter.state ? [filter.state] : []
    filter.productcategories = filter.productcategory
      ? [filter.productcategory]
      : []
    filter.accounts = filter.accountname ? [filter.accountname] : []

    let description = `${this.$refs.chart2L.title} [Period: ${moment(
      filter.period.startDate
    ).format('DD MMM, YY')} - ${moment(filter.period.endDate).format(
      'DD MMM, YY'
    )}; Market: ${filter.state ? filter.state : 'All'}; Category: ${
      filter.productcategory ? filter.productcategory : 'All'
    }; Account: ${filter.accountname ? filter.accountname : 'All'}]`

    this.$store.dispatch('drilldown.save', {
      filter: filter,
      parent: this.$route.name,
      description: description,
      method: 'Sales.InvoiceData',
      formatColumns: ['Spent']
    })

    //open new page
    this.$router.push({
      name: 'Chart drilldown'
    })
  },

  onChart2LTableMount () {
    console.log('onChart2LTableMount', this.charts.chart2L.rawData)

    this.tables.chart2LTable.dataSet = this.charts.chart2L.rawData.map(i => ({
      Year: moment(i.date).format('YYYY'),
      Month: moment(i.date).format('MMMM'),
      'Product Category': i.productcategory,
      '$ Sold': i.total
    }))
  }
}
